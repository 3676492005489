import Link, { LinkProps } from 'next/link';
import React from 'react';
import classnames from 'classnames';
import { isUrlObject } from '@/utils/utils';
import dayjs from 'dayjs';
import styles from './NewsList.module.scss';

export interface NewsListItem {
  href: LinkProps['href'];
  title: string;
  releaseTime: string;
}

export interface NewsListProps {
  className?: string;
  style?: React.CSSProperties;
  formatTemplate?: string;
  items: NewsListItem[];
  showDate?: boolean;
}

const NewsList: React.FC<NewsListProps> = ({
  items,
  className,
  style,
  formatTemplate = 'MM月DD日',
  showDate = true,
}) => {
  return (
    <div className={classnames(styles.container, className)} style={style}>
      <ul>
        {items.map(item => (
          <li key={isUrlObject(item.href) ? item.href.href : item.href}>
            <Link href={item.href}>
              <a>
                <span className={styles.title}>{item.title}</span>
                {showDate && (
                  <span className={styles.date}>
                    {dayjs(item.releaseTime).format(formatTemplate)}
                  </span>
                )}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsList;
